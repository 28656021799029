import React, { memo, useState } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import BtnsBlock from './BtnsBlock';
import DataBlock from './DataBlock';
import PaymentModal from '../../Modals/PaymentModal/PaymentModal';
import EarlySavingsModal from '../../Modals/EarlySavingsModal/EarlySavingsModal';
import QRPaymentModal from '../../Modals/QRPaymentModal/QRPaymentModal';
import QrSbpModal from '../../Modals/QRSbpModal/QrSbpModal';

const OpenCard = ({ record, setVisible, colNum }) => {
  const [payment, setPayment] = useState(false);
  const [early, setEarly] = useState(false);
  const [qr, setQr] = useState(false);
  const config = useSelector((state) => state.config);
  const savingSettings = config['1c_configs'].return.НастройкиСбережений;
  const [type, setType] = useState('ПоступлениеСредствНаДоговорСбережения');

  const getBtnsBlock = () => {
    return (
      <BtnsBlock
        settings={savingSettings}
        setVisible={setVisible}
        uid={record.UIDДоговора}
        openPayment={setPayment}
        openEarly={() => setEarly(true)}
        openQR={setQr}
      />
    );
  };

  return (
    <>
      <div className='more-info-wrapper'>
        {colNum === 24 ? (
          // mobile
          <>
            <Row>
              <Col span={colNum}>{getBtnsBlock()}</Col>
            </Row>
            <Row>
              <Col span={colNum}>
                <DataBlock record={record} />
              </Col>
            </Row>
          </>
        ) : (
          // desctop
          <>
            <Row>
              <Col span={colNum}>
                <DataBlock record={record} />
              </Col>
              <Col span={colNum}>{getBtnsBlock()}</Col>
            </Row>
          </>
        )}
      </div>
      <PaymentModal
        visible={payment}
        onCancel={() => setPayment(false)}
        initSum={0}
        type='ПоступлениеСредствНаДоговорСбережения'
        uid={record.UIDДоговора}
        docNum={record.НомерДоговора}
        title='Пополнение'
        btnText='Перейти к пополнению'
        tab={{ id: 4, title: 'Сбережения' }}
      />

      <EarlySavingsModal
        visible={early}
        onCancel={() => setEarly(false)}
        // initSum={initSum}
        type='ПлатежПоДоговоруЗайма'
        uid={record.UIDДоговора}
        docNum={record.НомерДоговора}
        title='Заявление на досрочное снятие сбережений'
        btnText='Да'
        tab={{ id: 4, title: 'Сбережения' }}
      />
      {qr !== 'sbp' ? (
        <QRPaymentModal
          visible={qr}
          onCancel={() => setQr(false)}
          title='Пополнить QR-кодом'
          initVals={{
            UIDДоговора: record.UIDДоговора,
            ВидДоговора: 'ДоговорСбережения',
            Сумма: '0',
            ОтчетКодСбер: qr === 'sber' ? true : false,
          }}
        />
      ) : (
        <QrSbpModal
          visible={qr}
          onCancel={() => setQr(false)}
          title={'Оплатить QR-кодом CБП'}
          initVals={{
            UIDДоговора: record.UIDДоговора,
            ВидДоговора: 'ДоговорСбережения',
            Сумма: 0,
            ТипОперации: type,
            СБП: true,
          }}
        />
      )}
    </>
  );
};

export default memo(OpenCard);
