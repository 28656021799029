import React from 'react';

const Contacts = () => {
  return (
    <div className='msgs-holder'>
      <p>Фактический адрес: 655017, республика Хакасия, город Абакан, улица Ленинского Комсомола, дом 11, помещение 22н</p>
      <p>Электронная почта: <a href="mailto:sodplus-2002@mail.ru">sodplus-2002@mail.ru</a></p>
      <p>Телефоны: <a href="tel:8(3902)222777">8(3902)222777</a></p>
      <p>Реквизиты:
        НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ КРЕДИТНЫЙ ПОТРЕБИТЕЛЬСКИЙ КООПЕРАТИВ ГРАЖДАН "СОДЕЙСТВИЕ ПЛЮС"
        ОГРН 1021900524469 от 2 октября 2002 г.
        ИНН/КПП 1901055649/190101001</p>
      <p>Юридический адрес
        655017, республика Хакасия, город Абакан, улица Ленинского Комсомола, дом 11, помещение 22н
        Телефоны: 8(3902)222777</p>
    </div>
  );
};

export default Contacts;
