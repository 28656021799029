import React, { memo, useEffect, useState, useCallback } from 'react';
import { Form, Slider, Input, Checkbox, Select, Switch, InputNumber, Button, DatePicker, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/ru_RU';
import makeNumberPretty from '../../../../heplers/makeNumberPretty';
import { getCurentData } from '../../../../heplers/dataCoverter';
import { getCreditPaymentTable } from '../../../../redux/ducks/user';
import LoanTableModal from '../../Modals/LoanTableModal/LoanTableModal';
import LoanTermsModal from '../../Modals/LoanTermsModal/LoanTermsModal';
import { createSpecificMessage } from '../../../../redux/ducks/user';

const { TextArea } = Input;
const { Option } = Select;

const LoanForm = ({
  form,
  setDisabled,
  sliderValues,
  setSliderValues,
  isLoading,
  loanType,
  changeLoanType,
  needHideLoanCheckbox,
}) => {
  const loans = useSelector((state) => state.user.credit.loans);
  const requisites = useSelector((state) => state.user.credit.requisites);
  const dispatch = useDispatch();
  const [ loanTable, setLoanTable ] = useState(false);
  const [ fileList, setFileList ] = useState([]);
  const config = useSelector((state) => state.config);

	const props = {
		onRemove: (file) => {
			const index = fileList.indexOf(file);
			const newFileList = fileList.slice();
			newFileList.splice(index, 1);
			setFileList(newFileList);
		},
		beforeUpload: (file) => {
			let a = [ ...fileList, file ];
			setFileList(a);
			return false;
		},
		fileList
	};

	const normFile = (e) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

  const getCreditTableData = useCallback(() => {
    let formData = form.getFieldsValue();
    let vals = {
      ВидЗайма: {
        UIDЗайма: loanType.UIDЗайма,
      },
      Срок: formData.Срок,
      Сумма: formData.Сумма,
      ОжидаемаяДатаВыдачи: getCurentData(),
      РасчетПСК: false,
    };
    // console.log(vals)
    dispatch(getCreditPaymentTable(vals));
    setLoanTable(true);
  }, [form, loanType.UIDЗайма, dispatch]);
  const specificMessage = useSelector((state) => state.user.credit.specificMessage);

  const [IsLoanTermsVisible, setIsLoanTermsVisible] = useState(false);

  useEffect(() => {
    if (sliderValues.first) {
      let c = loanType.СуммаМакс - loanType.СуммаМин;
      let d = c / 100;
      let h = sliderValues.first * d;
      let a = h + +loanType.СуммаМин;
      // console.log(a);
      let g = Math.round(a / 1000) * 1000;
      // let b = makeNumberPretty(g);
      form.setFieldsValue({ Сумма: g });
    } else {
      form.setFieldsValue({ Сумма: loanType.СуммаМин });
    }
  }, [sliderValues.first, loanType, form]);

  useEffect(() => {
    // console.log(sliderValues.second)
    if (sliderValues.second) {
      let a =
        sliderValues.second * ((loanType.СрокМакс - loanType.СрокМин) / 100) + +loanType.СрокМин;
      form.setFieldsValue({ Срок: Math.ceil(a) });
    } else {
      form.setFieldsValue({ Срок: loanType.СрокМин });
    }
  }, [sliderValues.second, loanType, form]);

  const marks = {
    0: loanType.СуммаМин ? makeNumberPretty(loanType.СуммаМин) : '0',
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: <>{loanType.СуммаМакс ? makeNumberPretty(loanType.СуммаМакс) : '100'}</>,
    },
  };

  const marks2 = {
    0: loanType.СрокМин ? `${loanType.СрокМин} ${loanType.ЕдИзмСрока}` : `1 ${loanType.ЕдИзмСрока}`,
    100: {
      style: {
        whiteSpace: 'nowrap',
      },
      label: (
        <>
          {loanType.СрокМакс
            ? `${loanType.СрокМакс} ${loanType.ЕдИзмСрока}`
            : `12 ${loanType.ЕдИзмСрока}`}
        </>
      ),
    },
  };

  return (
    <>
      <Form
        form={form}
        layout='vertical' // "horizontal"
        name='loan_modal'
        initialValues={{
          ЕдИзмСрока: loanType.ЕдИзмСрока,
        }}
        size={'middle'}
      >
        <Form.Item name='ВидЗайма' label='Вид займа'>
          <Select
            placeholder='Выберите вид займа'
            onSelect={(a, b) => {
              changeLoanType(b['data-el']);
              setSliderValues({ first: 0, second: 0 });
            }}
          >
            {loans.Займ && Array.isArray(loans.Займ) ? (
              loans.Займ.map((el, ind) => (
                <Option key={el.Код + ind} value={el.НаименованиеДляПечати} data-el={el}>
                  {el.НаименованиеДляПечати}
                </Option>
              ))
            ) : (
              <Option
                key={loans.Займ.Код}
                value={loans.Займ.НаименованиеДляПечати}
                data-el={loans.Займ}
              >
                {loans.Займ.НаименованиеДляПечати}
              </Option>
            )}
          </Select>
        </Form.Item>
        {loanType.UIDЗайма && (
          <>
            {/* Какая сумма Вам нужна? */}
            <div className='loan-input-group'>
              <Form.Item name='ЕдИзмСрока' className='loan-none'>
                <Input autoComplete='off' autoCorrect='off' spellCheck='false' readOnly />
              </Form.Item>
              <div className='loan-input-item'>
                <p>Какая сумма Вам нужна?</p>
                <Form.Item name='Сумма' className='loan-input'>
                  <InputNumber
                    suffix='руб.'
                    min={loanType.СуммаМин}
                    max={loanType.СуммаМакс}
                    formatter={(value) =>
                      `${value} руб.`.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
                    }
                    parser={(value) => value.replace(/(\s*)руб.?|(\s*)/g, '')}
                    onChange={(val) => {
                      if (val) {
                        let c = loanType.СуммаМакс - loanType.СуммаМин;
                        let d = c / 100;
                        let g = (val - loanType.СуммаМин) / d;
                        setSliderValues({ ...sliderValues, first: g });
                      } else {
                        form.setFieldsValue({ Сумма: loanType.СуммаМин });
                        setSliderValues({ ...sliderValues, first: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, first: val });
                }}
                defaultValue={0}
                step={0.1}
                marks={marks}
                tooltipVisible={false}
                value={sliderValues.first}
                disabled={isLoading}
              />
            </div>

            {/* На какой период? */}
            <div className='loan-input-group loan-input-group-month'>
              <div className='loan-input-item'>
                <p>На какой период?</p>
                <Form.Item name='Срок' className='loan-input'>
                  <InputNumber
                    min={loanType.СрокМин}
                    max={loanType.СрокМакс}
                    formatter={(value) => `${value} ${loanType.ЕдИзмСрока}`}
                    // parser={(value) => loanType.ЕдИзмСрока === 'мес.' ? value.replace(/(\s*)мес./g, '') : value.replace(/(\s*)дн./g, '')}
                    parser={(value) =>
                      value.replace(new RegExp(`(\\s*)${loanType.ЕдИзмСрока}`, 'g'), '')
                    }
                    onChange={(val) => {
                      if (val) {
                        let c = loanType.СрокМакс - loanType.СрокМин;
                        let d = c / 100;
                        let g = (val - loanType.СрокМин) / d;
                        setSliderValues({ ...sliderValues, second: g });
                      } else {
                        form.setFieldsValue({ Срок: loanType.СрокМин });
                        setSliderValues({ ...sliderValues, second: 0 });
                      }
                    }}
                  />
                </Form.Item>
              </div>
              <Slider
                onChange={(val) => {
                  setSliderValues({ ...sliderValues, second: val });
                }}
                defaultValue={0}
                step={(loanType.СрокМакс - loanType.СрокМин) / 100}
                marks={marks2}
                tooltipVisible={false}
                value={sliderValues.second}
                disabled={isLoading}
              />
            </div>
            <div className='loan__graph-btn'>
              <Button type='link' onClick={getCreditTableData}>
                Показать график платежей
              </Button>
            </div>

            {/* Динамические данные из реквизитов */}
            {requisites &&
              requisites.Реквизит &&
              requisites.Реквизит.filter((el) => !needHideLoanCheckbox ? !el.БезАвторизации : el).map((el) => {
                if (el.ВнутреннийТип === 'Дата рождения') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={
                        el.Обязателен && [
                          {
                            required: true,
                            message: `Заполните поле`,
                          },
                        ]
                      }
                    >
                      <DatePicker locale={locale} format='DD.MM.YYYY' />
                    </Form.Item>
                  );
                } else if (el.ВнутреннийТип === 'Мобильный телефон') {
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={[
                        el.Обязателен
                          ? {
                              required: true,
                              message: 'Заполните номер мобильного телефона',
                            }
                          : {},
                        { max: 10, message: 'Номер слишком длинный' },
                        { min: 10, message: 'Номер слишком короткий' },
                      ]}
                      // hasFeedback
                    >
                      <Input
                        addonBefore='+7'
                        type='number'
                        autoComplete='off'
                        autoCorrect='off'
                        spellCheck='false'
                        allowClear
                      />
                    </Form.Item>
                  );
                } else if (el.ВнутреннийТип === 'Произвольный реквизит (булево)') {
                  return (
                    // <Form.Item
                    //   key={el.UIDРеквизита}
                    //   name={el.Наименование}
                    //   label={el.Наименование}
                    //   valuePropName='checked'
                    //   {...tailLayout}
                    // >
                    // <Checkbox readOnly={isLoading} />
                    // </Form.Item>
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      valuePropName='checked'
                    >
                      <Switch />
                    </Form.Item>
                  );
                } else {
                  // console.log(el.ВнутреннийТип);
                  return (
                    <Form.Item
                      key={el.UIDРеквизита}
                      name={el.Наименование}
                      label={el.Наименование}
                      rules={
                        el.Обязателен && [
                          {
                            required: true,
                            message: `Заполните поле`,
                          },
                        ]
                      }
                    >
                      <Input
                        type={
                          el.ВнутреннийТип === 'Произвольный реквизит (число)'
                            ? 'number'
                            : el.ВнутреннийТип === 'Произвольный реквизит (строка)'
                            ? 'string'
                            : el.ВнутреннийТип === 'СНИЛС'
                            ? 'string'
                            : 'string'
                        }
                        autoComplete='off'
                        autoCorrect='off'
                        spellCheck='false'
                        allowClear
                        // onChange={(val) => console.log(val)}
                      />
                    </Form.Item>
                  );
                }
              })}

            {/* Загрузка файлов */}
            {config['1c_configs'].return.НастройкиЗаявокНаЗайм.ОтображатьПрисоединениеФайлов && 
              <label style={{ display: 'block' }} htmlFor="msg_modal_upload">
                <Form.Item
                  name="upload"
                  // label="Upload"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  // extra="longggggggggggggggggggggggggggqgggggggg"
                >
                  <Upload
                    name="logo"
                    listType="text"
                    multiple={true}
                    accept="image/*, .doc, .docx, application/pdf, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    {...props}
                  >
                    <Button disabled={isLoading}>
                      <UploadOutlined /> Прикрепить файлы
                    </Button>
                  </Upload>
                </Form.Item>
              </label>
            }

            {/* Комментарий */}
            <div className='loan-comment'>
              <p>Комментарий</p>
              <Form.Item name='Описание' className='loan-input'>
                <TextArea rows={4} readOnly={isLoading} />
              </Form.Item>
            </div>

            {/* Подтверждение */}
            {!needHideLoanCheckbox ? (
              <div className='loan-input-item loan-input-item__start'>
                <Form.Item name='remember' valuePropName='checked'>
                  <Checkbox
                    readOnly={isLoading}
                    onChange={(e) => {
                      if (e.target.checked && !specificMessage.UIDСообщения) {
                        dispatch(createSpecificMessage({ type: 'УсловияВыдачиЗайма' }));
                      }
                      setDisabled(!e.target.checked);
                    }}
                    className='loan-input-item__start-check'
                  >
                    <Button
                      type='link'
                      onClick={() => {
                        if (!specificMessage.UIDСообщения) {
                          dispatch(createSpecificMessage({ type: 'УсловияВыдачиЗайма' }));
                        }
                        setIsLoanTermsVisible(true);
                      }}
                    >
                      С условиями выдачи ознакомлен и согласен
                    </Button>
                    {/* <p>
                    <span className='sms-error'>*</span> Нажимая кнопку "Отправить заявку на
                    рассмотрение" я даю согласие на отправку запроса в бюро кредитных историй и
                    обработку персональных данных, с тарифами и условиями выдачи займа ознакомлен и
                    согласен.
                  </p> */}
                  </Checkbox>
                </Form.Item>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </Form>

      <LoanTableModal visible={loanTable} onClose={() => setLoanTable(false)} sum={() => form.getFieldsValue().Сумма}/>

      {/* Модалка документов из спецсообщения с условиями выдачи*/}
      <LoanTermsModal
        visible={IsLoanTermsVisible}
        onCancel={() => setIsLoanTermsVisible(false)}
        title='Условия выдачи займа'
      />
    </>
  );
};

export default memo(LoanForm);
