const addUserToLocal = data => {
  // console.log(data)
  localStorage.setItem('user', JSON.stringify(data.НаименованиеКлиента));
  if (data.phone) {
    localStorage.setItem('ph', JSON.stringify(data.phone));
  };
  localStorage.setItem('uid_ses', JSON.stringify(data.UIDСессии));
  localStorage.setItem('uid_cli', JSON.stringify(data.UIDКлиента));
};

export default addUserToLocal;