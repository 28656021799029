import React, { memo, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMessages, clearMessages, changeMsgWork } from '../../../../../redux/ducks/messages';
import MessageItem from './components/MessageItem';
import { Spin, message } from 'antd';

const Messages = ({ setInitMsg, newMsg, setNewMsg }) => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initScroll, setInitScroll] = useState(1);
  const [numberOfPackage, setNumberOfPackage] = useState(0);
  const [currentPackageLength, setCurrentPackageLength] = useState(0);

  const messages = useSelector((state) => state.messages.data);
  const messagesLoader = useSelector((state) => state.messages.isLoading);
  const msgWork = useSelector((state) => state.messages.msgWork);

  const messagesEndRef = useRef(null);
  const msgHolder = useRef(null);
  const firstMsg = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessages(0));
  }, [dispatch]);

  const getData = useCallback(() => {
    let a = msgHolder.current;
    let scroll = a.scrollTop;
    if (!scroll) {
      if (initScroll < numberOfPackage) {
        setIsLoading(true);
        let b = initScroll + 1;
        setInitScroll(b);
        dispatch(getMessages(initScroll));
      } else {
        message.info('Больше нет сообщений');
      }
    };
  }, [msgHolder, dispatch, initScroll, numberOfPackage]);

  useEffect(() => {
    let a = msgHolder.current;
    a.addEventListener('scroll', getData);
    return () => {
      a.removeEventListener('scroll', getData);
    };
  }, [ msgHolder, getData ]);

  useEffect(() => {
    return () => {
      dispatch(clearMessages());
    };
  }, [dispatch]);

  const scrollToBottom = () => {
    messagesEndRef.current && messagesEndRef.current.scrollIntoView({ behavior: 'smooth' }); // { behavior: 'smooth' }
  };
  const scrollToTop = () => {
    firstMsg.current && firstMsg.current.scrollIntoView();
  };

  useEffect(() => {
    if (messages.СообщенияЛК) {
      let packageLength = data.length;
      let arr = data;
      // console.log(arr);
      if (messages.СообщенияЛК.ИсходящиеСообщения) {
        if (Array.isArray(messages.СообщенияЛК.ИсходящиеСообщения)) {
          arr = [...arr, ...messages.СообщенияЛК.ИсходящиеСообщения];
        } else {
          let a = [];
          a.push(messages.СообщенияЛК.ИсходящиеСообщения);
          arr = [...arr, ...a];
        }
      }
      if (messages.СообщенияЛК.ВходящиеСообщения) {
        if (Array.isArray(messages.СообщенияЛК.ВходящиеСообщения)) {
          arr = [...arr, ...messages.СообщенияЛК.ВходящиеСообщения];
        } else {
          let a = [];
          a.push(messages.СообщенияЛК.ВходящиеСообщения);
          arr = [...arr, ...a];
        }
      }

      let arr2 = arr.sort((a, b) => {
        var va = !a.Дата ? '' : '' + a.Дата,
          vb = !b.Дата ? '' : '' + b.Дата;
        return va > vb ? 1 : va === vb ? 0 : -1;
      });

      setCurrentPackageLength(packageLength ? arr2.length - packageLength : arr2.length);

      let tmpArray = [];
      const itemCheck = (item) => {
        if (tmpArray.indexOf(item.UIDСообщения) === -1) {
          tmpArray.push(item.UIDСообщения);
          return true;
        }
        return false;
      };

      if (!data.length) {
        setTimeout(scrollToBottom, 0);
        setNumberOfPackage(+messages.КоличествоПакетов);
      } else if (newMsg) {
        let aa = arr2.filter((item) => itemCheck(item));
        arr2 = aa;
        setNewMsg(false);
        setTimeout(scrollToBottom, 0);
      } else {
        setTimeout(scrollToTop, 0);
      };
      setIsLoading(false);
      setData(arr2);
    }
  }, [messages]);

  const editingMsgWork = useCallback(() => {
    if (msgWork) {
      let arr2 = data;

      let tmpArray = [];
      const itemCheck = (item) => {
        if (tmpArray.indexOf(item.UIDСообщения) === -1) {
          tmpArray.push(item.UIDСообщения);
          return true;
        }
        return false;
      };

      let aa = arr2.filter((item) => itemCheck(item));

      if (msgWork) {
        let bb = aa.map((el) =>
          el.UIDСообщения === msgWork.UIDСообщения ? { ...el, ...msgWork } : el,
        );
        arr2 = bb;
        dispatch(changeMsgWork(false));
      }

      setData(arr2);

      if (msgWork.Прочитано) {
        message.info('Получение сообщения подтверждено');
      }
      if (msgWork.Подписан) {
        message.info('Сообщение успешно подписано');
      }

    }
  }, [msgWork, data, dispatch]);

  useEffect(() => {
    if ( msgWork ) {
      editingMsgWork();
    };
  }, [ msgWork, editingMsgWork ]);

  const getRef = (ind) => {
    return ind === currentPackageLength ? firstMsg : null;
  };

  return (
    <div
      className='msgs-holder'
      ref={msgHolder}
      // style={{ height: 'calc(100vh - 64px - 65.8px - 54.4px - 1.5rem)', overflow: 'auto' }}
    >
      {messagesLoader && !data.length ? (
        <div className='msg-loader'>
          <Spin size='large' />
        </div>
      ) : data.length > 0 ? (
        <>
          {isLoading && (
            <div className='msg-loader'>
              <Spin size='middle' />
            </div>
          )}

          {data.map((el, ind) => (
            <MessageItem
              ref={getRef(ind)}
              item={el}
              key={el.UIDСообщения}
              setInitMsg={setInitMsg}
            />
          ))}
          <div ref={messagesEndRef}></div>
        </>
      ) : (
        <p className='no-data'>У вас нет сообщений</p>
      )}
    </div>
  );
};

export default memo(Messages);

// <div className='align-left' style={{ marginBottom: '1rem'}}>
// <Card className='card'>
//   <p style={{ display: 'flex', justifyContent: 'space-between' }}>
//     <span>
//       <strong>Администратор </strong>
//       <span className='text-span'>№ ААББ-00000002 от 30.03.2020 в 16:24</span>
//     </span>
//     <span>
//       {/* <SubnodeOutlined/> */}
//         <img src={reload} alt='icon' onClick={() => setInitMsg({ Автор: 'aaaa' })}/>
//     </span>
//   </p>
//   <p>
//     <span className='text-span'>Статус:</span> <span>Получено</span>
//   </p>

//   {/* <div className='reply-msg'>
//     <p>Ответ на сообщение № ААББ-00000026 от 08.05.2020 14:35:18</p>
//   </div> */}
//   <p>
//     <span className='text-muted'>Тема: </span>
//     <span>Тест </span>
//   </p>
//   <p>Текст текст текст. Просмотрите все прикрепленные файлы</p>

//   <div className='links-holder'>
//     <p>
//       <PaperClipOutlined /> lll.jpg
//     </p>
//     <p>
//       <PaperClipOutlined /> dddd.pdf
//     </p>
//     <p>
//       <PaperClipOutlined /> Hello-im-realy-gib-dkdkkd-file.pdf
//     </p>
//   </div>
// </Card>
// </div>

// <div className='align-right'>
// <Card className='card'>
//   <p style={{ display: 'flex', justifyContent: 'space-between' }}>
//     <span>
//       <strong>Вы </strong>
//       <span className='text-span'>№ ААББ-00000002 от 30.03.2020 в 16:24</span>
//     </span>
//     {/* <span>
//       {/* <SubnodeOutlined/> */}
//       {/* <img src={reload} alt='icon' /> */}

//     {/* </span> */}
//   </p>
//   <p>
//     <span className='text-span'>Статус:</span> <span>Получено</span>
//   </p>

//   <div className='reply-msg'>
//     <p>Ответ на сообщение № ААББ-00000026 от 08.05.2020 14:35:18</p>
//   </div>
//   <p>
//     <span className='text-muted'>Тема: </span>
//     <span>Тест </span>
//   </p>
//   <p>Текст текст текст. Просмотрите все прикрепленные файлы</p>

//   <div className='links-holder'>
//     <p>
//       {' '}
//       <PaperClipOutlined /> lll.jpg
//     </p>
//     <p>
//       <PaperClipOutlined /> dddd.pdf
//     </p>
//     <p>
//       <PaperClipOutlined /> Hello-im-realy-gib-dkdkkd-file.pdf
//     </p>
//   </div>
// </Card>
// </div>
