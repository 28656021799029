import React, { memo } from 'react';
import { Row, Col } from 'antd';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

const DataBlock = ({ record }) => {
  const getFine = ({ vals, min }) => {
    let a = (+vals.Номинал) + (+vals.Комиссия) + (+vals.Пени) + (+vals.ПениКомиссия); // + (+vals.Проценты)
    let b = (+a) + (+vals.Проценты);
    // console.log(vals, b);
    if (min) {
      b = b - +min;
    }
    return `${makeNumberPretty(b)}`;
  };

  return (
    <>
      <Row>
        <Col span={12}>Сумма займа:</Col>
        <Col span={12}>{makeNumberPretty(record.СуммаДоговора)} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Остаток займа:</Col>
        <Col span={12}>{makeNumberPretty(record.пПлатеж.Номинал)} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Сумма в резерве:</Col>
        <Col span={12}>{makeNumberPretty(record.Резерв)} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Срок займа:</Col>
        <Col span={12}>
          {record.СрокДоговора} {record.Займ.ЕдИзмСрока}
        </Col>
      </Row>
      <Row className='cards-margin-bottom'>
        <Col span={12}>Дата окончания:</Col>
        <Col span={12}>{makeSimpleData(record.ДатаОкончания)}</Col>
      </Row>

      <Row>
        <Col span={12}>Просроченный платеж:</Col>
        <Col span={12}>{getFine({ vals: record.шПлатеж })} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Полный платеж:</Col>
        <Col span={12}>{getFine({ vals: record.пПлатеж })} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Платеж с учетом резерва:</Col>
        <Col span={12}>{getFine({ vals: record.тПлатеж, min: record.Резерв })} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Отношение:</Col>
        <Col span={12}>{record.Отношение}</Col>
      </Row>
    </>
  );
};

export default memo(DataBlock);
