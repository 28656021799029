import React from 'react';
import { Card, Checkbox } from 'antd';
import DocList from './components/DocList';

const Block2 = ({ active }) => {
  const { activeBlock, setActiveBlock } = active;

  return (
    <Card
      title='Ознакомьтесь, пожалуйста, с условиями получения займа'
      className={
        activeBlock === 2 || activeBlock === 3 ? 'esia-block' : 'esia-block unactive-block'
      }
    >
      <DocList />
      <Checkbox
        onChange={(e) => {
          if (e.target.checked) {
            setActiveBlock(3);
          } else {
            setActiveBlock(2);
          }
        }}
        style={{ marginTop: '1rem' }}
      >
        Я согласен и принимаю условия, указанные в перечисленных документах.
      </Checkbox>
    </Card>
  );
};

export default Block2;
