import { memo, useEffect, useMemo, useState } from 'react';
import { Card, Col, Typography, Row, Space, Radio, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';
import { DownloadFile } from './DownloadFile';
import { sendVote } from '../../../../../redux/ducks/vote';
import VoteAcceptSmsModal from '../../../../../common/components/Modals/VoteAcceptSmsModal/VoteAcceptSmsModal';

const { Text } = Typography

const VotePage = () => {
  const dispatch = useDispatch()
  const [acceptSmsModal, setAcceptSmsModal] = useState(false);
  const [initClean, setInitClean] = useState(false);
  const specificMessage = useSelector((state) => state.user.credit.specificMessage);

  const [answerState, setAnswerState] = useState({})
  const data = useSelector((state) => state.user.data);
  const voteData = data["ИнформацияОГолосовании"]

  useEffect(() => {
    const obj = voteData["СписокВопросов"]["ВопросГолосования"].reduce((acc, elem) => {
      acc[elem["UIDВопроса"]] = null
      return acc
    }, {})
    setAnswerState(obj)
  }, []);


  const changeAnswer = (index, answer) =>
    setAnswerState(state => ({ ...state, [index]: answer }));

  const isButtonDisable = useMemo(() =>
    Object.values(answerState).includes(null), [answerState])

  const changeModalVisible = () => setAcceptSmsModal(true)
  const sendVoidHandle = (smsCode) => {
    dispatch(sendVote(answerState, smsCode))
    setAcceptSmsModal(false)
  }

  return (
    <Card key="votepage" className='card-holder'>
      <Space direction="vertical" size="middle">
        <Col>
          <Row>
            <Text strong>По повестке: {voteData["Повестка"]}</Text>
          </Row>
          <Row>
            <Text strong>Проводится в период с {makeSimpleData(voteData["ДатаНачала"])} по {makeSimpleData(voteData["ДатаОкончания"])}</Text>
          </Row>
        </Col>
        {voteData["СписокВопросов"]["ВопросГолосования"].map((element, index) => (
          <Col>
            <Row>
              <Text>Голосование по вопросу №{index + 1} : {element["ПолноеПредставление"]}</Text>
            </Row>
            <Row>
            {element?.["ВложенныеФайлы"]?.["Файл"] &&
              <Col>
                <Row>
                  <Text>Дополнительные материалы: </Text>
                </Row>
                <Row>
                  <DownloadFile files={element?.["ВложенныеФайлы"]?.["Файл"]}/>
                </Row>
              </Col>}
            </Row>
            <Row>
              <Radio.Group value={answerState[element["UIDВопроса"]]} onChange={(e) => changeAnswer(element["UIDВопроса"], e.target.value)}>
                <Radio.Button value={1}>За</Radio.Button>
                <Radio.Button value={0}>Против</Radio.Button>
                <Radio.Button value={2}>Воздержался</Radio.Button>
              </Radio.Group>
            </Row>
          </Col>
        ))}
        <Button disabled={isButtonDisable} type="primary" onClick={changeModalVisible}>Проголосовать</Button>
      </Space>

      <VoteAcceptSmsModal
        onSuccessSmsAccept={sendVoidHandle}
        visible={acceptSmsModal}
        onCancel={() => setAcceptSmsModal(false)}
        flags={{ ПодписьЧерезСМС: specificMessage.ПодписьЧерезСМС }}
        UIDСообщения={specificMessage.UIDСообщения}
        initClean={{
          initClean: initClean,
          setInitClean: setInitClean,
        }}
        okText='Проголосовать'
        skipSmsTimeout
      />
    </Card>
  )
}

export default memo(VotePage)
