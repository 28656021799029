import React, { memo } from 'react';
import { Spin, Space } from 'antd';
import './Loader.less';

const Loader = ({ isLoading }) => {
  return (
    <Space
      size='middle'
      className={isLoading ? 'loader-wrapper' : 'loader-wrapper loader-wrapper-none'}
    >
      <Spin size='large' />
    </Space>
  );
};

export default memo(Loader);

