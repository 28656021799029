import React, { memo, useState, useEffect, useCallback } from 'react';
import { Form, Input, Button, message, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sendSms, checkSms, clearSmsServerResponse } from '../../../../../redux/ducks/sms';
import { makeSimpleTime } from '../../../../../heplers/dataCoverter';

const initLoaders = { again: false, send: false, init: false };

const AcceptPhoneSMSForm = ({
    setDisabled,
    setSmsWork,
    smsWork,
    visible,
    phone,
    registerEsiaUser
  }) => {
  const config = useSelector(state => state.config);

  const [loaders, setLoaders] = useState(initLoaders);
  const [err, setErr] = useState('');

  const [form] = Form.useForm();
  const serverResponse = useSelector((state) => state.sms.serverResponse);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      setLoaders((l) => {
        return { ...l, init: true };
      });
      dispatch(sendSms({ phone: phone.number, type: 'registration' }));
    };
  }, [visible, dispatch, phone.number]);

  const clearForm = useCallback(() => {
    form.resetFields();
    setLoaders(initLoaders);
    dispatch(clearSmsServerResponse());
    setErr('');
  }, [form, dispatch]);

  useEffect(() => {
    if (!visible) {
      clearForm();
    }
  }, [visible, clearForm]);

  useEffect(() => {
    if (visible) {
      if (serverResponse && serverResponse.status === 200 && (loaders.init || loaders.again)) {
        if (serverResponse.data.result === 'success') {
          message.info('Сообщение было отправлено на Ваш мобильный телефон');
        } else if (serverResponse.data.result === 'error') {
          const d1 = new Date(serverResponse.data.next_window_at * 1000);
          d1.toString();
          // console.log(d1);
          setErr(
            `Код уже был отправлен на указанный мобильный телефон, повторная отправка возможна в ${makeSimpleTime(
              d1,
            )}`,
          );
        } else {
          message.error('Ошибка сервера или смс провайдера');
        }
        setLoaders(initLoaders);
        dispatch(clearSmsServerResponse());
      };

      if (serverResponse && serverResponse.status === 200 && loaders.send) {
        if (serverResponse.data.result === 'success') {
          message.info('Смс код подтвержден');
          setDisabled({ is: false, КодСМС: serverResponse.code });

          registerEsiaUser();

        } else if (serverResponse.data.result === 'error') {
          message.error('Код неверен');
        } else {
          message.error('Ошибка соединения');
        }
        setLoaders((l) => {
          return { ...l, send: false };
        });
        dispatch(clearSmsServerResponse());
      };
    }
  }, [serverResponse, dispatch, setDisabled, visible, loaders, phone, registerEsiaUser ]);

  const sendSmsAgain = useCallback(() => {
    dispatch(sendSms({ phone: phone.number, type: 'registration' }));
    setLoaders({ ...loaders, again: true });
    setErr('');
  }, [dispatch, loaders, phone.number]);

  const onFinish = (values) => {
    dispatch(checkSms({...values, phone: phone.number}));
    setLoaders({ ...loaders, send: true });
    if (err) {
      setErr('');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Divider />
      <Form
        form={form}
        name='sms-form'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
      >
        <Form.Item
          label={`Для подтверждения номера сотового телефона на сайте ${config.name && config.name} введите код ХХХХХХ.`}
          name='code'
          rules={[
            {
              required: true,
              message: 'Введите код из смс',
            },
          ]}
        >
          <Input
            onChange={(e) => {
              if (e.target.value) {
                if (!smsWork) {
                  setSmsWork(true);
                }
              } else {
                if (smsWork) {
                  setSmsWork(false);
                }
              }
            }}
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            allowClear
            // readOnly={!disabled.is}
          />
        </Form.Item>
        <div>
          <div className='sms-btns-wrapper'>
            <Button
              loading={loaders.again}
              onClick={sendSmsAgain}
              // disabled={!disabled.is}
              className='sms-btn-again'
            >
              Отправить код еще раз
            </Button>

            <Button
              type='primary'
              className='sms-btn-accept'
              htmlType='submit'
              loading={loaders.send}
              // disabled={!disabled.is}
            >
              Продолжить
            </Button>
          </div>
          {err && <p className='sms-error'>{err}</p>}
        </div>
      </Form>
    </>
  );
};

export default memo(AcceptPhoneSMSForm);
