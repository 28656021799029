import moment from 'moment';

export const makeSimpleData = date => {
    return date ? moment(date).format('DD.MM.YYYY') : undefined
};

export const makeDataWithTime = date => {
    return moment(date).format('DD.MM.YYYY в HH:mm') // ss
}

export const makeSimpleTime = date => {
    return moment(date).format('HH:mm:ss');
}

export const getCurentData = () => {
    return moment().format('YYYY-MM-DD')
}