import React, { memo } from 'react';
import { Row, Col } from 'antd';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

const DataBlock = ({ record }) => {
  // console.log(record)
  return (
    <>
      <Row>
        <Col span={12}>Наименование:</Col>
        <Col span={12}>{record.Сбережение.Наименование}</Col>
      </Row>
      <Row>
        <Col span={12}>Сумма:</Col>
        <Col span={12}>{makeNumberPretty(record.СуммаДоговора)} руб.</Col>
      </Row>
      <Row>
        <Col span={12}>Срок:</Col>
        <Col span={12}>
          {record.СрокДоговора} {record.Сбережение.ЕдИзмСрока}
        </Col>
      </Row>
      <Row className='cards-margin-bottom'>
        <Col span={12}>Дата окончания:</Col>
        <Col span={12}>{makeSimpleData(record.ДатаОкончания)}</Col>
      </Row>

      <Row>
        <Col span={12}>Процентная ставка:</Col>
        <Col span={12}>{record.Сбережение.СтавкаПроценты}%</Col>
      </Row>
      <Row className='cards-margin-bottom'>
        <Col span={12}>Процентная ставка досрочного снятия:</Col>
        <Col span={12}>{record.Сбережение.СтавкаДосрочногоСнятия}%</Col>
      </Row>

      <Row>
        <Col span={12}>В залоге:</Col>
        <Col span={12}>{record.ВЗалоге ? 'Да' : 'Нет'}</Col>
      </Row>
      <Row>
        <Col span={12}>Можно снять номинал:</Col>
        <Col span={12}>{record.МожноСнятьНоминал ? 'Да' : 'Нет'}</Col>
      </Row>
      <Row>
        <Col span={12}>Можно снять проценты:</Col>
        <Col span={12}>{record.МожноСнятьПроценты ? 'Да' : 'Нет'}</Col>
      </Row>
    </>
  );
};

export default memo(DataBlock);
