import React, { memo, useEffect, useCallback, useState } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { createEarlyPayment, clearPaymentServerResponse } from '../../../../../redux/ducks/payment';
import { getUserData } from '../../../../../redux/ducks/user'


const EarlyPaymentModal = ({ visible, onCancel, uid, type, initSum, title, btnText }) => {
  const [isLoading, changeIsLoading] = useState(false);
  const serverResponse = useSelector((state) => state.payment.serverResponse);

  const dispatch = useDispatch();

  const onCreate = () => {
    const vals = {
      ВидГашения: "Полное",
      ИсточникГашения: "Прочее",
      UIDДоговора: uid,
    }
    changeIsLoading(true);
    dispatch(createEarlyPayment(vals))
  };

  const closeConfirm = () => {
    onCancel();
  };

  const earlyPaymentErrorConfirm = useCallback((err) => {
    Modal.error({
      title: 'Ошибка!',
      content: err,
      centered: true,
      onOk: () => {},
    });
  }, []);

  const earlyPaymentSuccessConfirm = useCallback(() => {
    Modal.success({
      title: 'Готово!',
      content: 'Заявка на полное досрочное погашения займа отправлена',
      centered: true,
      onOk: () => {
        dispatch(getUserData())
        onCancel();
      },
    });
  }, [onCancel, dispatch]);

  useEffect(() => {
    if (serverResponse && serverResponse.status === 200 && isLoading) {
      changeIsLoading(false);
      // console.log(serverResponse)
      if (serverResponse.data.return.КодОшибки === '0') {
        earlyPaymentSuccessConfirm(serverResponse.data.return.ОписаниеОшибки);
      } else {
        earlyPaymentErrorConfirm(serverResponse.data.return.ОписаниеОшибки);
      }
      dispatch(clearPaymentServerResponse());
    }
    if (
      serverResponse &&
      (serverResponse.status === 400 ||
        serverResponse.status === 422 ||
        serverResponse.status === 500) &&
      isLoading
    ) {
      earlyPaymentErrorConfirm(serverResponse.data);
      changeIsLoading(false);
      dispatch(clearPaymentServerResponse());
    }
  }, [serverResponse, earlyPaymentSuccessConfirm, earlyPaymentErrorConfirm, isLoading, dispatch]);

  return (
    <Modal
      open={visible}
      title={title}
      okText={btnText}
      cancelText='Нет'
      onCancel={closeConfirm}
      onOk={onCreate}
      centered
      confirmLoading={isLoading}
    >
      <h3>Отправить заявку на полное досрочное погашения займа?</h3>
    </Modal>
  );
};

export default memo(EarlyPaymentModal);
