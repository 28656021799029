import { combineReducers } from 'redux';

import user from './ducks/user';
import messages from './ducks/messages';
import globalError from './ducks/globalError';
import sms from './ducks/sms';
import payment from './ducks/payment';
import config from './ducks/config';

const rootReducer = combineReducers({
  user,
  messages,
  sms,
  payment,
  globalError,
  config,
});

export default rootReducer;
