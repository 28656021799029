const makeNumberPretty = num => {
  if (num) {
    let nn = (+num).toFixed(2);
    let n = nn.toString();
    return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
    // (/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')
    // replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }
  return num;
};

export default makeNumberPretty;