// const INN = process.env.REACT_APP_INN;
// const APP_VERSION = process.env.REACT_APP_APP_VERSION;

const getDefaultObject = () => {
  let config = JSON.parse(localStorage.getItem('conf'));
  let obj = {
    ИННОрганизации: config.code,
    ВерсияПриложения: config.app_version,
    UIDКлиента: JSON.parse(localStorage.getItem('uid_cli')),
    UIDСессии: JSON.parse(localStorage.getItem('uid_ses')),
  };
  return obj
};

export default getDefaultObject;