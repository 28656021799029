import React, { memo, useState, useEffect } from 'react';
import { Table, Card } from 'antd';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

const columns = [
  {
    title: 'Номер',
    dataIndex: 'number',
    key: 'number',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => (
      <p className='table-number'>
        {record.НомерЗаявки} <br />
        <span className='text-secondary'> от {makeSimpleData(record.ДатаЗаявки)}</span>
      </p>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => getStatus(record),
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => <p className='table-number'>{makeNumberPretty(record.Сумма)} руб.</p>,
  },
  {
    title: 'Вид сбережения',
    dataIndex: 'type',
    key: 'type',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => (
      <p>
        {record.Сбережение ? record.Сбережение.НаименованиеДляПечати : '-'}
      </p>
    ),
  },
  {
    title: 'Срок',
    key: 'tags',
    dataIndex: 'tags',
    // responsive: ['md'],
    align: 'center',
    render: (text, record) => (
      <>
        <p className='table-number'>
          {record.Срок} {record.ЕдИзмСрока}
        </p>
      </>
    ),
  },
];

const getStatus = (record) => {
  if (!record.Аннулирована && !record.ОформленДоговор && !record.Одобрена && !record.Отказана) {
    return <p className=''>На рассмотрении</p>;
    // UIDЗаявки: "7000a401-0d3e-11eb-a9a6-4ccc6ab801e5"
    // Аннулирована: false
    // ДатаЗаявки: "2020-10-13T17:25:39"
    // ЕдИзмСрока: "мес."
    // НомерЗаявки: "ААВВ-00000001"
    // Одобрена: false
    // Отказана: false
    // ОформленДоговор: false
    // Сбережение: {UIDСбережения: "84423daa-00fe-11ea-b55d-949ba261bb3a", Код: "00000000001", Наименование: "Накопительный", НаименованиеДляПечати: "", СтавкаПроценты: "9.5", …}
    // Срок: "3"
    // Сумма: "5000"
  } else if (record.ОформленДоговор) {
    return <p className='text-success'>Оформлен Договор</p>;
  } else if (record.Аннулирована) {
    return <p className=''>Аннулирована</p>;
  } else if (record.Одобрена) {
    return <p className='text-success'>Одобрена</p>;
  } else if (record.Отказана) {
    return <p className='text-fail'>Отказана</p>;
  }
};

const SavingApplications = ({ isLoading }) => {
  const { width } = useWindowSize();

  const userData = useSelector((state) => state.user.data);

  const [tableData, changeTableData] = useState([]);

  useEffect(() => {
    // console.log(userData);
    if (
      userData &&
      userData.ИнформацияОЗаявкахНаСбережение &&
      userData.ИнформацияОЗаявкахНаСбережение.ИнформацияОЗаявкеНаСбережение
    ) {
      if (Array.isArray(userData.ИнформацияОЗаявкахНаСбережение.ИнформацияОЗаявкеНаСбережение)) {
        changeTableData(userData.ИнформацияОЗаявкахНаСбережение.ИнформацияОЗаявкеНаСбережение);
      } else {
        let a = [];
        a.push(userData.ИнформацияОЗаявкахНаСбережение.ИнформацияОЗаявкеНаСбережение);
        changeTableData(a);
      }
    }
  }, [userData]);

  // console.log(tableData);

  return !isLoading ? (
    width >= 882 ? (
      tableData.length ? (
        // <div className='default-tabs-holder'>
          <Table
            className='table-width'
            // style={{ width: '100%' }}
            rowKey={(record) => record.UIDЗаявки}
            columns={columns}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 64px - 65.8px - 54.4px - 5rem)' }}
            // locale={{ emptyText: '' }}
            locale={{ emptyText: 'Нет заявок на сбережения' }}
            // locale = {{emptyText: <Empty style={{margin: 'auto', width: '100%'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />}}
          />
        // </div>
      ) : (
        <p className='no-data'> У Вас нет заявок на сбережения</p>
      )
    ) : (
      <div
        className='card-wrapper'
        // style={{ height: 'calc(100vh - 64px - 65.8px - 54.4px - 3rem)', overflow: 'auto' }}
      >
        {tableData.length ? (
          tableData.map((el) => {
            // console.log(el);
            return (
              <Card key={el.UIDЗаявки} className='card-holder'>
                <div className='card-row'>
                  <span className='card-title'>Номер</span>
                  <p className='table-number'>
                    {el.НомерЗаявки} <br />
                    <span className='text-secondary'> от {makeSimpleData(el.ДатаЗаявки)}</span>
                  </p>
                </div>
                <div className='card-row'>
                  <span className='card-title'>Статус</span>
                  {getStatus(el)}
                </div>
                <div className='card-row'>
                  <span className='card-title'>Сумма</span>
                  <p>{makeNumberPretty(el.Сумма)} руб.</p>
                </div>
                <div className='card-row'>
                  <span className='card-title'>Вид сбережения</span>
                  <p>{el.Сбережение ? el.Сбережение.НаименованиеДляПечати : '-'}</p>
                </div>
                <div className='card-row'>
                  <span className='card-title'>Срок</span>
                  <p>
                    {el.Срок} {el.ЕдИзмСрока}
                  </p>
                </div>
              </Card>
            );
          })
        ) : (
          <p className='no-data'> У Вас нет заявок на сбрежения</p>
        )}
      </div>
    )
  ) : (
    <></>
  );
};

export default memo(SavingApplications);
