import React, { memo } from 'react';
import { Form, Input } from 'antd';

const Phone = () => {
  return (
    <Form.Item
      name='phone'
      label='Номер мобильного телефона'
      rules={[
        {
          required: true,
          message: 'Заполните номер мобильного телефона',
        },
        { max: 10, message: 'Номер слишком длинный' },
        { min: 10, message: 'Номер слишком короткий' },
      ]}
      hasFeedback
    >
      <Input
        addonBefore='+7'
        type='number'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        allowClear
      />
    </Form.Item>
  );
};

export default memo(Phone);