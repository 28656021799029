import React, { useState, memo, useEffect } from 'react';
import { Form, Select } from 'antd';

import LoanAgreementNumber from '../../Inputs/LoanAgreementNumber';
import SavingsContractNumber from '../../Inputs/SavingContractNumber';
import Phone from '../../Inputs/Phone';
import Name from '../../Inputs/Name';
import Password from '../../Inputs/Password';
import RePassword from '../../Inputs/RePassword';
import PasswordRules from '../../PasswordRules/PasswordRules';

const { Option } = Select;

const getRegisterTypeFields = registerType => {
  if (registerType === 'ПоНомеруДоговораЗаймаИМобильному') {
    return (
      <>
        <LoanAgreementNumber />
        <Phone />
      </>
    );
  } else if (registerType === 'ПоНомеруДоговораЗаймаИНаименованию') {
    return (
      <>
        <LoanAgreementNumber />
        <Name />
      </>
    );
  } else if (registerType === 'ПоНомеруДоговораСбереженияИНаименованию') {
    return (
      <>
        <SavingsContractNumber />
        <Name />
      </>
    );
  } else if (registerType === 'ПоНаименованиюИМобильному') {
    return (
      <>
        <Name />
        <Phone />
      </>
    );
  } else {
    return <></>;
  }
};

const RegistrationForm = ({ form, isLoading, visible }) => {

  const [registerType, setRegisterType] = useState('ПоНомеруДоговораЗаймаИМобильному');

  useEffect(() => {
    if (!visible) {
      setRegisterType('ПоНомеруДоговораЗаймаИМобильному');
    }
  }, [visible])
  return (
    <>
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          register_type: 'ПоНомеруДоговораЗаймаИМобильному',
        }}
      >
        <Form.Item name='register_type' label='Способ регистрации'>
          <Select
            placeholder='Select a option and change input text above'
            onChange={value => setRegisterType(value)}
          >
            <Option value='ПоНомеруДоговораЗаймаИМобильному'>По номеру договора займа и мобильному телефону</Option>
            <Option value='ПоНомеруДоговораЗаймаИНаименованию'>По номеру договора займа и Ф.И.О.</Option>
            <Option value='ПоНомеруДоговораСбереженияИНаименованию'>По номеру договора сбережения и Ф.И.О.</Option>
            <Option value='ПоНаименованиюИМобильному'>По Ф.И.О. и мобильному телефону</Option>
          </Select>
        </Form.Item>

        {getRegisterTypeFields(registerType)}

        <Password isLoading={isLoading} />

        <small className='form-text'>
          <PasswordRules/>
        </small>

        <RePassword isLoading={isLoading} />
      </Form>
    </>
  );
};

export default memo(RegistrationForm);
