import React, { memo, useState, useEffect } from 'react';
import { Table, Card } from 'antd';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { useSelector } from 'react-redux';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';
import { makeSimpleData } from '../../../../../heplers/dataCoverter';

const columns = [
  {
    title: 'Номер',
    dataIndex: 'number',
    key: 'number',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => (
      <p className='table-number'>
        {record.НомерЗаявки} <br />
        <span className='text-secondary'> от {makeSimpleData(record.ДатаЗаявки)}</span>
      </p>
    ),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => getStatus(record)
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    key: 'sum',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => <p className='table-number'>{makeNumberPretty(record.Сумма)} руб.</p>,
  },
  {
    title: 'Вид займа',
    dataIndex: 'type',
    key: 'type',
    responsive: ['md'],
    align: 'center',
    render: (text, record) => <p>{record.Займ ? (record.Займ.НаименованиеДляПечати) : '-'}</p>,
  },
  {
    title: 'Срок',
    key: 'tags',
    dataIndex: 'tags',
    // responsive: ['md'],
    align: 'center',
    render: (text, record) => (
      <>
        <p className='table-number'>{record.Срок} {record.ЕдИзмСрока}</p>
      </>
    ),
  },
];

const getStatus = record => {
  if (!record.Аннулирована && !record.Выдана && !record.Одобрена && !record.Отказана) {
    return <p className=''>На рассмотрении</p>;
    // UIDЗаявки: "60b081bd-a40f-11ea-a9a4-4ccc6ab801e5"
    // Аннулирована: false
    // Выдана: false
    // ДатаЗаявки: "2020-06-01T20:54:15"
    // ЕдИзмСрока: "дн."
    // Займ: {UIDЗайма: "84423d8e-00fe-11ea-b55d-949ba261bb3a", Код: "00000000001", Наименование: "До зарплаты 150%", НаименованиеДляПечати: "До зарплаты 150%", СтавкаПроценты: "150", …}
    // НомерЗаявки: "ААВВ-00000001"
    // Одобрена: true
    // Отказана: false
    // Срок: "15"
    // Сумма: "30000"
  } else if (record.Выдана) {
    return <p className='text-success'>Выдана</p>;
  } else if (record.Аннулирована) {
    return <p className=''>Аннулирована</p>;
  } else if (record.Одобрена) {
    return <p className='text-success'>Одобрена</p>;
  } else if (record.Отказана) {
    return <p className='text-fail'>Отказана</p>;
  };
};


const LoanApplications = ({ isLoading }) => {
  const { width } = useWindowSize();

  const userData = useSelector(state => state.user.data);

  const [tableData, changeTableData] = useState([]);

  useEffect(() => {
    if (
      userData && userData.ИнформацияОЗаявкахНаЗайм
      && userData.ИнформацияОЗаявкахНаЗайм.ИнформацияОЗаявкеНаЗайм
    ) {
      if (Array.isArray(userData.ИнформацияОЗаявкахНаЗайм.ИнформацияОЗаявкеНаЗайм)) {
        changeTableData(userData.ИнформацияОЗаявкахНаЗайм.ИнформацияОЗаявкеНаЗайм);
      } else {
        let a = [];
        a.push(userData.ИнформацияОЗаявкахНаЗайм.ИнформацияОЗаявкеНаЗайм);
        changeTableData(a);
      }
    }
  }, [userData]);

  return (
    !isLoading ? width >= 882 ? (
    tableData.length ?
    <Table
      className='table-width'
      // style={{ width: '100%' }}
      rowKey={record => record.UIDЗаявки}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{ y: 'calc(100vh - 64px - 65.8px - 54.4px - 5rem)' }}
      // locale={{ emptyText: '' }}
      locale={{ emptyText: 'Нет заявок на займ' }}
      // locale = {{emptyText: <Empty style={{margin: 'auto', width: '100%'}} image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />}}
    />
    :
    <p className='no-data'> У Вас нет заявок на займ</p>
  ) : (
      <div
        className='card-wrapper'
        // style={{ height: 'calc(100vh - 64px - 65.8px - 54.4px - 3rem)', overflow: 'auto' }}
      >
        {tableData.length ? tableData.map(el => {
          // console.log(el);
          return (
            <Card key={el.UIDЗаявки} className='card-holder'>
              <div className='card-row'>
                <span className='card-title'>Номер</span>
                <p className='table-number'>
                  {el.НомерЗаявки} <br />
                  <span className='text-secondary'> от {makeSimpleData(el.ДатаЗаявки)}</span>
                </p>
              </div>
              <div className='card-row'>
                <span className='card-title'>Статус</span>
                {getStatus(el)}
              </div>
              <div className='card-row'>
                <span className='card-title'>Сумма</span>
                <p>{makeNumberPretty(el.Сумма)} руб.</p>
              </div>
              <div className='card-row'>
                <span className='card-title'>Вид займа</span>
                <p>{el.Займ ? el.Займ.НаименованиеДляПечати : '-'}</p>
              </div>
              <div className='card-row'>
                <span className='card-title'>Срок</span>
                <p>{el.Срок} {el.ЕдИзмСрока}</p>
              </div>
            </Card>
          );
        })
        :
        <p className='no-data'> У Вас нет заявок на займ</p>
        }
      </div>
      )
      : <></>
  );
};

export default memo(LoanApplications);
