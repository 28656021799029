import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { List } from 'antd';
import { FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { loadFile } from '../../../../../redux/ducks/messages';

const DocList = () => {
  const [docList, setDocList] = useState([]);

  const loanTerms = useSelector((state) => state.user.credit.loanTerms);
	const specificMessage = useSelector((state) => state.user.credit.specificMessage);

	const dispatch = useDispatch();

  useEffect(() => {
    if (loanTerms.ПечатнаяФорма) {
      if (Array.isArray(loanTerms.ПечатнаяФорма)) {
        setDocList(loanTerms.ПечатнаяФорма);
      } else {
        let arr = [];
        arr.push(loanTerms.ПечатнаяФорма);
        setDocList(arr);
      }
    }
	}, [loanTerms]);

	useEffect(() => {
		if(specificMessage.ВложенныеФайлы) {
			let files = specificMessage.ВложенныеФайлы.Файл;
			let arr = [];
			if (Array.isArray(files)) {
				arr = [...files];
			} else {
				let a = [];
				a.push(files);
				arr = [...arr, ...a];
			};
			setDocList(arr);
		};
	}, [ specificMessage ]);

  return (
    <List
      size='small'
      // header={<div>Ознакомьтесь, пожалуйста, с условиями получения займа</div>}
      // footer={<div>Footer</div>}
      bordered
      dataSource={docList.length ? docList : [{ Представление: 'Загрузка...' }]}
      renderItem={(item) => (
        <List.Item className={specificMessage.ВложенныеФайлы ? 'clickable-item' : ''} onClick={specificMessage.ВложенныеФайлы ? () => dispatch(loadFile(item.UIDФайла, false)) : undefined }>
          {specificMessage.ВложенныеФайлы ? (
            <>
              <PaperClipOutlined className='doc-icon'/> {item.Наименование}.{item.РасширениеФайла}
            </>
          ) : (
            <>
              <FileOutlined className='doc-icon' />
              {item.Представление}
            </>
          )}
        </List.Item>
      )}
    />
  );
};

export default DocList;
