import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

const PhoneForm = ({ onFinishPhone, phone, phoneIsntReady }) => {
  const [isPhone, setIsPhone] = useState(false);

  const [form] = Form.useForm();

  return (
    <div>
      <Form
        form={form}
        name='login-form'
        initialValues={{ remember: true }}
        onFinish={onFinishPhone}
        layout='vertical'
      >
        <Form.Item
          name='phone'
          // label='Укажите номер мобильного телефона'
          rules={[
            { required: true, message: 'Введите номер телефона!' },
            { max: 10, message: 'Номер слишком длинный' },
            { min: 10, message: 'Номер слишком короткий' },
          ]}
          hasFeedback
        >
          <Input
            autoComplete='off'
            autoCorrect='off'
            spellCheck='false'
            type='number'
            size='large'
            placeholder='Номер телефона'
            prefix={!isPhone ? <PhoneOutlined className='site-form-item-icon' /> : '+7'}
            onFocus={(e) => setIsPhone(true)}
            onBlur={(e) => (!e.target.value ? setIsPhone(false) : undefined)}
            onChange={(e) => {
              if(phone.isReady){
                phoneIsntReady();
              };
            }}
            // readOnly={isLoading}
            // allowClear
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            className='login-form-button'
          >
            Подтвердить телефон
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PhoneForm;