import React, { memo } from 'react';
import { Row, Col } from 'antd';
import makeNumberPretty from '../../../../../heplers/makeNumberPretty';

const DataBlock = ({ record }) => {
  return (
    <>
      <Row>
        <Col span={12}>Тип взноса:</Col>
        <Col span={12}>{record.Взнос.ТипВзноса}</Col>
      </Row>
      <Row>
        <Col span={12}>Назначение:</Col>
        <Col span={12}>{record.Взнос.НазначениеВзноса}</Col>
      </Row>
      <Row>
        <Col span={12}>Остаток:</Col>
        <Col span={12}>
          {makeNumberPretty(record.ОстатокНоминал)} руб.
        </Col>
      </Row>
      <Row>
        <Col span={12}>Проценты:</Col>
        <Col span={12}>
          {makeNumberPretty(record.ОстатокПроценты)} руб.
        </Col>
      </Row>
      <Row>
        <Col span={12}>Налог:</Col>
        <Col span={12}>
          {makeNumberPretty(record.ОстатокНалог)} руб.
        </Col>
      </Row>
      <Row>
        <Col span={12}>Долг:</Col>
        <Col span={12}>
          {makeNumberPretty(record.Долг)} руб.
        </Col>
      </Row>
    </>
  );
};

export default memo(DataBlock);
