import React, { memo } from 'react';
import { Form } from 'antd';
import Password from '../../../../../../common/components/Inputs/Password';
import RePassword from '../../../../../../common/components/Inputs/RePassword';
import PasswordRules from '../../../../../../common/components/PasswordRules/PasswordRules';

const RegistrationForm = ({ form, isLoading }) => {

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        name='change_password_form'
        className='change-password-form'
      >
        <Password isLoading={isLoading} />
        <RePassword isLoading={isLoading} />
        <div>
          <PasswordRules/>
        </div>
      </Form>
    </>
  );
};

export default memo(RegistrationForm);
