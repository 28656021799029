import React, { memo } from 'react';
import { Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';


const Name = () => {
  return (
    <Form.Item
      name='name'
      label='Ф.И.О.'
      rules={[
        {
          required: true,
          message: 'Заполните Ф.И.О',
        },
      ]}
    >
      <Input
        prefix={<UserOutlined className='site-form-item-icon' />}
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        allowClear
      />
    </Form.Item>
  );
};

export default memo(Name);