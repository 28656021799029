import { PaperClipOutlined } from '@ant-design/icons';
import { Typography, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { loadVoteFile } from '../../../../../redux/ducks/vote';

const { Link } = Typography

export const DownloadFile = ({ files }) => {
  const dispatch = useDispatch();
  const download = (uid, owner) =>
    dispatch(loadVoteFile(uid, owner));

  if (files === undefined) return
  if (Array.isArray(files)) return <Space direction="horizontal" size="small">
    {files.map(file =>
      <Link onClick={() => download(file["UIDФайла"], file["Владелец"])}>
        <PaperClipOutlined />{file["Наименование"]}.{file["РасширениеФайла"]}
      </Link>
    )}
  </Space>

  if (typeof files === "object") {
    return <Link onClick={() => download(files["UIDФайла"], files["Владелец"])}>
      <PaperClipOutlined />{files['Наименование']}.{files['РасширениеФайла']}
    </Link>;
  }
};
